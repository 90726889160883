import React, { createContext } from 'react';
import { ProjectOutputModel } from 'dd-client';

interface ProjectContextProps {
  project: ProjectOutputModel | undefined;
  setProject: (project: ProjectOutputModel) => void;
}

const ProjectContext = createContext<ProjectContextProps>({
  project: {} as ProjectOutputModel,
  setProject: () => {}
});

const ProjectProvider = ({ children }: any) => {
  const [project, setProject] = React.useState<ProjectOutputModel>();

  return <ProjectContext.Provider value={{ project, setProject }}>{children}</ProjectContext.Provider>;
};


/**
 * Main interface for the project context. Exposes Project setter and getter.
 * While 'project' is nullable, it is safe to assume it will be defined for any 
 * descendant of the /pages/project/index.tsx.
 * 
 * Cons of this approach:
 * - 'project' is nullable, so you have to check for nullability in every component.
 * - (minor) Because it's a hook, Project component needs to wait a full render cycle after it calls setProject(project) before rendering its children.
 * 
 * Future intentions: 
 * I'd really like to make the project non-nullable. Maybe I can do that by:
 * - Make 'project' non-nullable and move this Provider back down the heirarchy to within the Project page.
 * - use a hook to fetch the project from the context outside of the Project page. I think this is actually not possible.
 *
 * @returns {ProjectContextProps} The project context properties.
 */
const useProjectContext: () => ProjectContextProps = () => {
  const context = React.useContext(ProjectContext);

  if (!context) {
    throw new Error(
      'useProjectContext must be used within a ProjectProvider. Make sure to wrap your component in a ProjectProvider component to get access to the project context.'
    );
  }

  return context;
};

export { ProjectProvider, useProjectContext };
